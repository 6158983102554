import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Banner = styled.section`
  align-items: flex-end;
  background: ${orange.base};
  min-height: 180px;

  @media(min-width: ${breakpoints.md}) {
    min-height: 210px;
  }
  @media(min-width: ${breakpoints.lg}) {
    min-height: 255px;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 270px;
  }
`

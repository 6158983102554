import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'

import GeneralSearchStyle from './style'

type GeneralSearchProps = {
  initialValue?: string;
  noContainerBackground: boolean;
  styles?: string;
}

const GeneralSearch = ({ initialValue, noContainerBackground, styles }: GeneralSearchProps) => {
  const textInput = useRef(null)
  const [ searchText, setSearchText ] = useState(initialValue)
  const [ timeoutId, setTimeoutId ] = useState(null)

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setSearchText(valueInput)
    if (timeoutId) { clearTimeout(timeoutId) }
    const newTimeoutId = setTimeout(() => {
      navigate(`/search?q=${encodeURIComponent(valueInput)}`)
    }, 300)
    setTimeoutId(newTimeoutId)
  }

  useEffect(() => {
    textInput.current.focus()
  }, [])

  return (
    <GeneralSearchStyle id='search-form' noContainerBackground={noContainerBackground} className={styles}>
      <input
        type='text'
        placeholder='O que você está procurando? Busque aqui!'
        className='px-3 text-center lh-17'
        maxLength={150}
        onChange={handleChange}
        value={searchText}
        ref={textInput}
      />
    </GeneralSearchStyle>
  )
}

GeneralSearch.defaultProps = {
  initialValue: '',
  noContainerBackground: false,
  styles: '',
}

export default GeneralSearch

import React, { useEffect, useState, useRef } from 'react'
import { PageProps } from 'gatsby'
import { format, parseISO } from 'date-fns'
import axios from 'axios'
import Layout from 'src/components/Layout'
import BlogArticle from 'src/components/BlogArticle'
import GeneralSearch from 'src/components/GeneralSearch'
import LoadingIndicator from 'src/components/LoadingIndicator'
import SEO from 'src/components/Seo'
import AddThis from 'src/components/AddThis'
import BreadCrumb from 'src/components/Breadcrumb'

import { Banner } from './style'

type Article = {
  id: string;
  slug: string;
  title: string;
  introduction: string;
  grid_image_url: string;
  published_at: string;
  updated_at: string;
  category_name: string;
  fields: {
    [param: string]: string;
  };
}

const POSTS_PER_PAGE = 50

const Search = ({ location }: PageProps) => {
  const [ articles, setArticles ] = useState([])
  const loadingRef = useRef<SVGSVGElement>(null)
  const [ loadingVisible, setLoadingVisible ] = useState(true)
  const urlSearchParams = new URLSearchParams(location.search)
  const searchText = urlSearchParams.get('q')

  async function runSearch (text: string) {
    setLoadingVisible(true)
    const response = await axios.get(`${process.env.SEARCH_ENDPOINT}?q=${text}&size=${POSTS_PER_PAGE}`)
    setArticles(response.data.hits.hit)
    setLoadingVisible(false)
  }

  useEffect(() => {
    runSearch(searchText || '')
  }, [ searchText ])

  const breadcrumbPath = [
    {
      text: 'Blog',
      link: '/',
    },
    {
      text: 'Busca',
      link: '/search',
    },
  ]

  return (
    <Layout>
      <SEO
        url={`${process.env.BASE_URL}/search`}
        title='Busca · Blog do Inter'
        meta={[
          {
            name: 'description',
            content: 'Navegue por conteúdos sobre finanças, educação Financeira e novidades do Inter.',
          },
          {
            property: 'og:title',
            content: 'Busca · Blog do Inter',
          },
          {
            property: 'og:description',
            content: 'Navegue por conteúdos sobre finanças, educação Financeira e novidades do Inter.',
          },
        ]}
      />
      <h1 className='sr-only'>Buscar no blog do Inter</h1>
      <Banner className='d-flex'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 mb-3 pt-5'>
              <GeneralSearch initialValue={searchText || ''} noContainerBackground />
              <h2 className='fs-20 fs-md-24 lh-28 fw-400 text-white mt-md-3 mt-lg-3 mt-xl-4'>
                Sua busca por: <span className='fs-600 text-uppercase fs-md-32 fs-lg-40'>{searchText}</span>
              </h2>
            </div>
          </div>
        </div>
      </Banner>

      <section id='compartilhe' className='d-flex bg-grayscale--100 py-3 justify-content-center align-items-center h-breadcrumb'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-lg-9'>
              <BreadCrumb path={breadcrumbPath} />
            </div>
            <div className='col-6 col-lg-3 text-right px-0 pr-lg-2'>
              <AddThis />
            </div>
          </div>
        </div>
      </section>

      <section id='artigos-do-blog' className='pt-4 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='row'>
                {!loadingVisible && !articles.length && (
                  <div className='col-12 text-center'>
                    Não encontramos nenhuma postagem correspondente ao termo de pesquisa informado.
                  </div>
                )}
                {
                  articles.map((item: Article) => {
                    return (
                      <div key={item.fields.id} className='col-12 col-md-6 col-lg-4'>
                        <BlogArticle
                          link={`/${item.fields.slug}/`}
                          image={item.fields.grid_image_url}
                          category={item.fields.category_name}
                          title={item.fields.title}
                          date={format(parseISO(item.fields.published_at), 'dd/MM/yyyy')}
                          description={item.fields.introduction}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div className='d-flex justify-content-center'>
                { loadingVisible && <LoadingIndicator ref={loadingRef} /> }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Search
